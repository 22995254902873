import React from 'react';
import { getVisitedWishlists } from '../History'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Favorite } from './Interfaces';

function generateUrlPath(wishlistId : string) {
    return '/w/' + wishlistId;
}

const Navigation = (props: {
    favorites: Favorite[];
}) => {

    const visited = getVisitedWishlists();

    return (
        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Brand href="/">
                <table>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/pic/logo_64.png" alt="Logo" width="64" height="80" className="d-inline-block align-text-middle" />
                                </td>
                                <td>
                                    <div className="main-header ms-4">MY-WISHER.COM</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Navbar.Brand>
                <Button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </Button>
                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="ms-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown title="Menu" id="home-nav-dropdown">
                            <NavDropdown.Item href="/">Home</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/updates">Updates</NavDropdown.Item>
                            <NavDropdown.Item href="/about">About</NavDropdown.Item>
                            <NavDropdown.Item href="/advertising">Advertising</NavDropdown.Item>
                            <NavDropdown.Item href="/privacy">Privacy</NavDropdown.Item>
                            <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
                            <NavDropdown.Item href="/faq">FAQ</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Favorites" id="favorites-nav-dropdown">
                            {
                                props && props.favorites && props.favorites.length > 0 ?
                                    props.favorites.map(x => {
                                        return <NavDropdown.Item key={x.wishlistId} href={generateUrlPath(x.wishlistId)} className="favorites-nav-dropdown-item">{x.wishlistName}</NavDropdown.Item>
                                    }) :
                                    <NavDropdown.Item href="#">No favorites added yet.</NavDropdown.Item>
                            }
                        </NavDropdown>
                        <NavDropdown title="History" id="history-nav-dropdown">
                            {
                                visited.length > 0 ?
                                    visited.map(x => {
                                        return <NavDropdown.Item key={x.id} href={generateUrlPath(x.id)} className="history-nav-dropdown-item" >{x.name}</NavDropdown.Item>
                                    }) :
                                    <NavDropdown.Item href="#">No wishlists visited yet.</NavDropdown.Item>
                            }
                        </NavDropdown>
                        <Nav.Link href="/logout">Sign-off{" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-indent" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M3 8a.5.5 0 0 1 .5-.5h6.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H3.5A.5.5 0 0 1 3 8Z"></path>
                                <path fillRule="evenodd" d="M12.5 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5Z"></path>
                            </svg>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
}

export default Navigation;