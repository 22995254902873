import React from 'react';
 
const Error = () => {
    return (
       <div>
          <p>Error: Page does not exist!</p>
          <p>Press <a href="/"> here </a> to go back to the main page? </p>
       </div>
    );
}
 
export default Error;