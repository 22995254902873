import React from 'react';
import Navigation from './Navigation';
import { Container, Row, Col, Card } from 'react-bootstrap';

const FaqItem = (props) => {

    return (
        <Row className="justify-content-md-center mt-3">
            <Col sm={12} md={8} lg={6}>
                <Card style={{textAlign: "center"}}>
                    <Card.Header><h4 className="dark-text" >{props.question}</h4></Card.Header>
                    <Card.Body>
                        <p>{props.answer}</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>);
}

const Faq = () => {
    return (
        <Container>
            <Navigation />
            <Row className="justify-content-md-center">
                <Col sm={12} md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <img src="pic/idea_128x128.png" alt="" align="left" style={{ padding: "0 10px 0 10px" }} />
                            <h3 className="dark-text webfont"> Frequently Asked Questions (FAQ) </h3>
                            <p>This page contains a list of common questions or clarifications regarding this site and it's services. This is a good place to start looking if you have a question or in some way is encountering problems. </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <FaqItem question="How can I register or create an account?"
                answer="Short answer is: you can't. Not in a traditional sense. You don't need (and cannot) create an account. Once you create an wishlist, you can bind it to your email such that you always can recover your administrative rights" />
            <FaqItem question="How can I regain/recover administrative access to my wishlist(s)?"
                answer="Short answer is: Use the form on the front page to get a login-link via email. This assumes that you did bound your wishlist to your email at some point; if not then there is no way to regain the administrative rights, sorry (but we did warn you)." />
            <FaqItem question="Where is my password?"
                answer="Short answer is: there is no password. Not in a traditional sense. We never ask you for an password. We do however assign an internal passkey to you which is used when via a login link. There is no need for you to remember this, just use the login link in the email." />
        </Container>
    );
}

export default Faq;