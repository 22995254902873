import React from 'react';
import Navigation from './Navigation';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Privacy = () => {
    return (
        <Container>
            <Navigation />
            <Row className="justify-content-md-center">
                <Col sm={12} md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <img src="pic/safe_128x128.png" alt="" align="left" style={{ padding: "0 10px 0 10px" }} />
                            <h3 className="dark-text webfont"> Privacy on My Wishlist </h3>
                            <p>The amount of personal information stored in our databases is <u>minimal</u>. Besides the wishlist data we only store your email if you decided to specify it when creating your wishlist. You are not even required to share your email with us. So even if someone was able to get ahold of our data they would not gain access to any sensitive data, not even a password, name, address and etc.</p>

                            <p>We leave cookies in your browser such that we may recognize your when you enter this website. These cookies will not be shared by other people or websites on the Internet.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Privacy;