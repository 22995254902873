import React from 'react';
import Navigation from './Navigation';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Contact = () => {

    const emailStr = "contact (@) my-wisher.com";

    return (
        <Container>
            <Navigation />
            <Row className="justify-content-md-center">
                <Col sm={12} md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <img src="pic/user_128x128.png" alt="" align="left" style={{ padding: "0 10px 0 10px" }} />
                            <h3 className="dark-text webfont"> Contacting My Wishlist </h3>
                            <p>This is a free and low-cost operation for us and hence, we cannot make any guarantees on replying to every request that occurs. With that said, we are naturally interested in getting feedback and ever improving this service that we are providing. As of this moment, we are working on creating a forum, support-system or a contact-form where everyone will be able to share their thoughts. For now all requests can be
                                emailed to <span id='contact-email' className="contact-email" style={{whiteSpace: "nowrap"}}>{emailStr}</span>.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Contact;