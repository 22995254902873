import React, { useState } from 'react';
import { Row, Col, Card, Button, Table } from 'react-bootstrap';
import WishlistPopUp from './WishlistPopUp'
import WishlistDeletePopup from './WishlistDeletePopUp'
import { WishlistItem } from './Interfaces';

function sliceLinkText(link: string) {
    const maxLength = 75;
    if (link && typeof link === 'string') {
        link = link.replace("https://", "");
        link = link.replace("http://", "");
        link = link.replace("www.", "");
        if (link.length <= maxLength) {
            return link;
        } else {
            return link.slice(0, maxLength - 3) + "...";
        }
    }
    return link;
}

const fallbackImgUrl = '/pic/gift-2.png';

interface EditWishlistItemParams {
    wishlistId: string;
    item: WishlistItem;
    onItemUpdate: (item: WishlistItem) => void;
    minPriority: number;
    maxPriority: number;
    position: number;
    numItems: number;
    onDeleted: (id: string) => void;
}

const EditWishlistItem = (props: EditWishlistItemParams) => {

    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const item = props.item;

    const handleDeletePopupCancel = (id: string) => {
        setShowDeletePopup(false);
    }

    const handleDeleted = (id: string) => {
        setShowDeletePopup(false);
        props.onDeleted(id);
    }

    const handlePopupClose = () => {
        setShowEditPopup(false);
    }

    const handleEdited = (item : WishlistItem) => {
        setShowEditPopup(false);
        props.onItemUpdate(item);
    }

    const handleShowDeletePopup = () => setShowDeletePopup(true);
    const handleShowEditPopup = () => setShowEditPopup(true);

    const hiddenIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
    </svg>

    return (
        <>
            <Row id={props.item.id}
                        className="edit-wishlist-item justify-content-md-center mb-4 mt-4">
                <Col sm={12} md={8} lg={8}>
                    <Card>
                        <Card.Header><Card.Title>{ (props.item.visible ? <></> : (<>{hiddenIcon}{" "}</>)) }{item.name}</Card.Title></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={12} lg={6}>
                                    <img src={item.image} alt={item.name} style={{ width: "100%" }} onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = fallbackImgUrl;
                                            }} />
                                </Col>
                                <Col sm={12} md={12} lg={6}>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Price</td>
                                                <td className='edit-price-view'>{item.price}</td>
                                            </tr>
                                            <tr>
                                                <td>Link</td>
                                                <td className='word-wrap'><a href={item.link} target="_BLANK" rel="noopener noreferrer">{sliceLinkText(item.link)}</a></td>
                                            </tr>
                                        </tbody>
                                    </Table></Col>
                            </Row>
                            <Row>
                                <Col>{item.text}</Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='text-end' style={{ background: '#f8f8f8' }}>
                            <div className="float-start">
                                <Button variant="primary" onClick={handleShowEditPopup} className="edit-wish-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                        <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z"></path>
                                        <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
                                    </svg>{' '}
                                    EDIT
                                </Button>
                            </div>
                            <div className="float-end">
                                <Button variant="primary" onClick={handleShowDeletePopup} className="trash-wish-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>{' '}
                                    TRASH
                                </Button>{' '}
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <WishlistPopUp
                wishlistId={props.wishlistId}
                item={item}
                show={showEditPopup}
                onClose={handlePopupClose}
                onItemUpdate={handleEdited}
                minPriority={props.minPriority}
                maxPriority={props.maxPriority}
                position={props.position}
                numItems={props.numItems}
            />
            <WishlistDeletePopup wishlistId={props.wishlistId} item={item} show={showDeletePopup} onCancel={handleDeletePopupCancel} onDeleted={handleDeleted} />
        </>
    );
}

export default EditWishlistItem;