import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Navigation from './Navigation';
import { get_wishlist, account, save_wishlist, date_to_datetime } from './MyWisher'
import EditWishlistHeader from './EditWishlistHeader';
import EditWishlistUrl from './EditWishlistUrl';
import EditWishlistItem from './EditWishlistItem';
import NewWishlistItem from './NewWishlistItem';
import { Spinner } from 'react-bootstrap';
import { NullableWishlist, WishlistHeader, WishlistItem, Favorite } from './Interfaces';

const EditWishlist = () => {

    const { wishlistId } = useParams()
    const [wishlist, setWishlistRaw] = useState<NullableWishlist>(null);
    const [favorites, setFavorites] = useState<Favorite[] | null>(null);

    const calledWishlistOnce = useRef(false);

    // process before setting state
    const setWishlist = (wishlistFn: (wishlist: NullableWishlist) => NullableWishlist) => {
        setWishlistRaw(wishlist => {
            const newWishlist = wishlistFn(wishlist);
            if (newWishlist !== null) {
                newWishlist.items.sort((a, b) => {
                    const p = a.priority - b.priority;
                    if (p !== 0) {
                        return p;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                    return 0;
                });
            }
            return newWishlist;
        });
    }

    const updateWishlist = (w: WishlistHeader) => {
        setWishlist((oldWishlist: NullableWishlist) => {
            if (oldWishlist === null) {
                return null;
            }
            const newWishlist = { ...oldWishlist };
            newWishlist.name = w.name;
            newWishlist.text = w.text;
            return newWishlist;
        });
    }

    const handleNew = (item: WishlistItem) => {
        console.log("handleNew");
        if (wishlist === null) {
            return null;
        }

        item.id = uuidv4();

        const newItems = [...wishlist.items, item];
        const newWishlist = {
            ...wishlist, "items": newItems
        }
        save_wishlist(newWishlist).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error("Response was not ok");
                throw new Error("Response was not ok");
            }
        }).then(data => {
            setWishlist(_ => data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            //setLoading(false);
        });
    };

    const handleUpdated = (item: WishlistItem) => {
        console.log("handleUpdated");
        if (wishlist === null) {
            return null;
        }
        const newItems = wishlist.items.map(x => {
            if (x.id === item.id) {
                return item;
            } else {
                return x;
            }
        });
        const newWishlist = {
            ...wishlist, "items": newItems
        }

        save_wishlist(newWishlist).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error("Response was not ok");
                throw new Error("Response was not ok");
            }
        }).then(data => {
            setWishlist(_ => data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            //setLoading(false);
        });

        

        var element = document.getElementById(item.id);
        if (element != null) {
            element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    };

    const handleDeleted = (id: string) => {
        if (wishlist === null) {
            console.warn("Cannot handle deleted item when no wishlist is present!");
            return;
        }

        const index = wishlist.items.findIndex((x) => x.id === id);

        const newItems = [...wishlist.items];
        newItems[index].archived = date_to_datetime(new Date());
        const newWishlist = {
            ...wishlist, "items": newItems
        }

        save_wishlist(newWishlist).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error("Response was not ok");
                throw new Error("Response was not ok");
            }
        }).then(data => {
            setWishlist(_ => data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            //setLoading(false);
        });
    }

    useEffect(() => {
        if (calledWishlistOnce.current) {
            return;
        }
        calledWishlistOnce.current = true;

        if (wishlistId) {
            get_wishlist(wishlistId)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.error("Response was not ok");
                    }
                    throw response;
                }).then(data => {
                    setWishlist(_ => data);
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                });
            
                account().then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.error("Response status code is ", response.status);
                    }
                }).then(data => {
                    setFavorites(data.favorites || []);
                }).catch(error => {
                    console.error(error);
                }).finally(() => {            
                });
        }
    });

    const handleWishlistUrlChange = (url: string) => {
        setWishlist((wishlist) => {
            if (wishlist === null) {
                return null;
            }
            return {
                ...wishlist, "url": url
            }
        });
    };

    var isLoading = wishlist === null;
    var items = wishlist !== null ? (wishlist.items ? wishlist.items : []) : [];

    const numItems = items.length;
    const priorities = items.map(x => Number(x.priority) || 0);
    const minPriority = Math.min(...priorities);
    const maxPriority = Math.max(...priorities);

    if (wishlist === null) {
        return (<Container><Navigation favorites={favorites || []} /></Container>);
    }

    return (
        <Container>
            <Navigation favorites={favorites || []} />
            <EditWishlistHeader wishlist={wishlist} onChange={(w: WishlistHeader) => updateWishlist(w)} />
            <EditWishlistUrl wishlist={wishlist} onUrlChange={handleWishlistUrlChange} />
            <NewWishlistItem
                onNewItem={handleNew}
                wishlistId={wishlist.wishlistId}
                minPriority={minPriority}
                maxPriority={maxPriority}
                position={0}
                numItems={numItems} />
            {
                isLoading ?
                    <div className="row gy-5 mb-4 mt-5">
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <h3>Loading</h3>
                        </div>
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <Spinner />
                        </div>
                    </div> : <></>
            }
            {
                items
                .filter(x => !x.archived)
                .map(x => {
                    const id = x.id;
                    return <EditWishlistItem
                        key={id}
                        wishlistId={wishlist.wishlistId}
                        item={x}
                        onItemUpdate={(item) => handleUpdated(item)}
                        onDeleted={(id) => handleDeleted(id)}
                        minPriority={minPriority}
                        maxPriority={maxPriority}
                        position={0}
                        numItems={numItems}
                    />
                })
            }
        </Container>

    );
}

export default EditWishlist;