import React from 'react';
import Navigation from './Navigation';
import { Container, Row, Col, Card } from 'react-bootstrap';

const About = () => {

   return (
      <Container>
         <Navigation />
         <Row className="justify-content-md-center">
            <Col sm={12} md={8} lg={6}>
               <Card>
                  <Card.Body>
                     <img src="pic/research_128x128.png" alt="" align="left" style={{padding: "0 10px 0 10px"}} />
                     <h3 className="dark-text webfont"> About My Wishlist </h3>
                     <p style={{textAlign: "justify"}}>There is not much to say. This is a free service provided to you because we believe that you should be able to create and maintain a wishlist as easily as possible. We hope to keep this site running solely on advertisement and donations. The project started out as a hobby and has been growing ever since. We hope that you (and everyone else) can make good use of our service.</p>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </Container>
   );
}

export default About;