interface VisitedWishlist {
    id: string,
    name: string,
    visited: Date
};

export function getVisitedWishlists() : VisitedWishlist[] {
    const visitedStr = localStorage.getItem("visited")
    if (visitedStr) {
        const visited : VisitedWishlist[] = JSON.parse(visitedStr);
        // transform id to lower case
        visited.forEach((e) => {
            e.id = e.id.toLowerCase();
        });
        // remove duplicates
        const unique = visited.filter((element, index) => {
            return visited.findIndex((x) => x.id === element.id) === index;
        });
        return unique;
    } else {
        return [];
    }
}

export function setVisitedWishlist(id : string, name : string, timestamp : Date) {
    let idLower = id.toLowerCase();
    let visited = getVisitedWishlists();
    visited = visited.filter(x => x.id.toLowerCase() !== idLower);
    visited.push({
        id: idLower,
        name: name,
        visited: timestamp
    });
    visited.sort((a,b) => a.name < b.name ? 1 : -1);

    const visitedStr = JSON.stringify(visited);
    localStorage.setItem("visited", visitedStr);
}