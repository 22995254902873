import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ViewWishlist from "./components/ViewWishlist";
import EditWishlist from "./components/EditWishlist";
import Updates from "./components/Updates";
import Advertising from "./components/Advertising";
import Privacy from "./components/Privacy";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import About from "./components/About";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Error from "./components/Error";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/w/:wishlistIdRaw" element={<ViewWishlist />} />
        <Route path="/e/:wishlistId" element={<EditWishlist />} />
        <Route path="/updates" element={<Updates />} />
        <Route path="/advertising" element={<Advertising />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/login/link" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);