import React, { useEffect, useRef, useState } from 'react';
import { setVisitedWishlist } from '../History'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Form, Table, Button } from 'react-bootstrap';
import Navigation from './Navigation';
import ViewWishlistItem from './ViewWishlistItem';
import { account, get_public_wishlist, get_public_wishlist_by_url, add_user_favorite, remove_user_favorite, date_to_datetime, is_user_logged_in } from './MyWisher';
import { Spinner } from 'react-bootstrap';
import { publicWishlistUrl, mapFavorite } from './Util'
import { Favorite, ApiFavorite, NullableWishlist, Wishlist } from './Interfaces';

/// parse id and determine whether it is a nice link or a wishlist id
/// return: [wishlistId, niceUrl] (at least one is null)
function parseWishlistId(value : string | null) {
    if (value != null) {
        if (value.length === 36) {
            return [value, null];
        } else {
            return [null, value];
        }
    } else {
        return [null, null];
    }
}

const ViewWishlist = () => {

    const { wishlistIdRaw } = useParams()
    const [wishlistId, setWishlistId] = useState<string | null>(null);
    const [wishlist, setWishlist] = useState<NullableWishlist>(null);
    const [isWishlistLoading, setIsWishlistLoading] = useState<boolean>(false);

    const [wishlistIdFromUrl, wishlistNiceUrl] = parseWishlistId(wishlistIdRaw ? wishlistIdRaw.toLowerCase().trim() : null);
    
    if (wishlistIdFromUrl && wishlistIdFromUrl !== wishlistId) {
        setWishlistId(wishlistIdFromUrl);
    }

    const [showReserved, setShowReserved] = useState(false);

    const [isFavorite, setIsFavorite] = useState(false);
    const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
    const [favorites, setFavorites] = useState<Favorite[]>([]);

    const showReservationSwitch = true;

    const calledWishlistOnce = useRef(false);
    const calledFavoritesOnce = useRef(false);

    useEffect(() => {
        if (wishlistNiceUrl && wishlist === null) {
            
            if (calledWishlistOnce.current) {
                return;
            }
            calledWishlistOnce.current = true;

            setIsWishlistLoading(true);
            get_public_wishlist_by_url(wishlistNiceUrl)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } 
                    console.error("response error", response);
                    throw response;
                }).then(payload => {
                    const w = payload;
                    setWishlist(w);
                    setVisitedWishlist(w.wishlistId, w.name, new Date());
                    setWishlistId(w.wishlistId);                   
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    setIsWishlistLoading(false);
                });
        }

    }, [wishlistNiceUrl, wishlist]);

    useEffect(() => {
        const isNiceUrlPresent = (typeof wishlistNiceUrl === 'string') && wishlistNiceUrl.length > 4;
        
        if (wishlistId && isNiceUrlPresent === false && wishlist === null) {
            if (calledWishlistOnce.current) {
                return;
            }
            calledWishlistOnce.current = true;
    
            setIsWishlistLoading(true);
            get_public_wishlist(wishlistId)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    throw response;
                }).then(payload => {
                    const w : Wishlist = payload;
                    setWishlist(w);
                    setVisitedWishlist(wishlistId, w.name, new Date());
                    
                    const niceUrl = payload.url;
                    if (typeof niceUrl === 'string' && niceUrl.length>4) {
                        // Replace the current entry in the browser's history, without reloading
                        const newURL = publicWishlistUrl(niceUrl);
                        window.history.replaceState(null, "", newURL);
                    }
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    setIsWishlistLoading(false);
                });
        }
    }, [wishlistId, wishlist, wishlistNiceUrl]);

    useEffect(() => {
        if (wishlistId) {

            if (calledFavoritesOnce.current) {
                return;
            }
            calledFavoritesOnce.current = true;

            if (is_user_logged_in()===false) {
                return;
            }

            setIsFavoriteLoading(true);
            account().then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                throw response;
            }).then(account => {
                const l : ApiFavorite[] = account.favorites || [];
                const l2 = mapFavorite(l);
                setFavorites(l2);
                const idInList = l2.map(x => x.wishlistId).includes(wishlistId.toLocaleLowerCase());
                setIsFavorite(idInList);
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setIsFavoriteLoading(false);
            });
        }
    }, [wishlistId]);

    const handleReserve = (itemId : string) => {
        setWishlist(wishlist => {
            if (wishlist === null) {
                return null;
            }
            return {
                ...wishlist, "items": wishlist.items.map(item => {
                    if (item.id === itemId) {
                        const now = new Date();
                        item.reserved = now.toISOString();
                    }
                    return item;
                })
            }
        });
    };

    const handleFavorite = () => {
        if (wishlist === null) {
            return;
        }

        setIsFavoriteLoading(true);
        const newValue = isFavorite ? false : true;
        const promise = newValue ? add_user_favorite(wishlist.wishlistId, wishlist.name) : remove_user_favorite(wishlist.wishlistId);
        
        var newFavorites : Favorite[];
        if (newValue) {
            newFavorites = [...favorites];
            newFavorites.push({ wishlistId: wishlist.wishlistId, wishlistName: wishlist.name, link: '/w/' + wishlist.wishlistId, created: date_to_datetime(new Date()) });
        } else {
            newFavorites = favorites.filter(item => item.wishlistId.toLowerCase().trim() !== wishlist.wishlistId.toLowerCase().trim());
        }

        promise.then(response => {
            if (response.status === 200 || response.status === 201) {
                return response.json();
            } 
            throw Error("response error");
        }).then(_ => {
            setFavorites(newFavorites);
            setIsFavorite(newValue);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setIsFavoriteLoading(false);
        });

    }

    return (
        <Container>
            <Navigation favorites={favorites} />
            {wishlist != null ?
                <Row className="view-wishlist-header pb-md-4">
                    <Col sm={12} md={10} lg={8} className='col-10 col-sm-10 col-md-6 col-lg-4 card-container' style={{ margin: "auto" }}>
                        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                            <h1 className="display-4 fw-normal">{wishlist.name}</h1>
                            <p className="fs-5 text-muted">{wishlist.text}</p>
                        </div>
                    </Col>
                </Row>
                : (isWishlistLoading ?
                    <Row className="pb-md-4">
                        <Col sm={12} md={10} lg={8} className='col-10 col-sm-10 col-md-6 col-lg-4 card-container' style={{ margin: "auto" }}>
                            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                <span>Loading...</span>
                            </div>
                            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true" />
                            </div>
                        </Col>
                    </Row> :
                    <Row className="pb-md-4">
                        <Col sm={12} md={10} lg={8} className='col-10 col-sm-10 col-md-6 col-lg-4 card-container' style={{ margin: "auto" }}>
                            <div className="pricing-header p-3 pb-md-4 mx-auto text-center" style={{ fontSize: "10em" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-octagon-fill" viewBox="0 0 16 16">
                                    <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
                                </svg>
                            </div>
                            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                <h1>Wishlist was not found...</h1>
                            </div>
                        </Col>
                    </Row>
                )
            }
            {wishlist != null && showReservationSwitch ?
                <Row className="pb-md-4">
                    <Col sm={12} md={5} lg={3} style={{ margin: "auto" }}>
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "end" }}><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi-cart4" viewBox="0 0 16 16">  <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path></svg></td>
                                    <td style={{ textAlign: "start" }}>
                                        <Form>
                                            <Form.Check
                                                type="switch"
                                                label="Show Reservations?"
                                                onChange={e => { setShowReserved(e.target.checked); }}
                                                className="mt-3"
                                            />
                                        </Form>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col sm={12} md={5} lg={3} style={{ margin: "auto" }}>
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "end" }} onClick={handleFavorite}>
                                        <Button type="button" variant="outline-secondary">
                                            {isFavorite ? "Forget" : "Make Favorite"}
                                        </Button>
                                        <span className="ms-3">
                                            {isFavoriteLoading ?
                                                <Spinner></Spinner> :
                                                (isFavorite ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi-hearts" viewBox="0 0 16 16">
                                                        <path fillRule={"evenodd"} d="M4.931.481c1.627-1.671 5.692 1.254 0 5.015-5.692-3.76-1.626-6.686 0-5.015Zm6.84 1.794c1.084-1.114 3.795.836 0 3.343-3.795-2.507-1.084-4.457 0-3.343ZM7.84 7.642c2.71-2.786 9.486 2.09 0 8.358-9.487-6.268-2.71-11.144 0-8.358Z" />
                                                    </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi-heartbreak" viewBox="0 0 16 16">
                                                        <path d="M8.867 14.41c13.308-9.322 4.79-16.563.064-13.824L7 3l1.5 4-2 3L8 15a38.094 38.094 0 0 0 .867-.59Zm-.303-1.01-.971-3.237 1.74-2.608a1 1 0 0 0 .103-.906l-1.3-3.468 1.45-1.813c1.861-.948 4.446.002 5.197 2.11.691 1.94-.055 5.521-6.219 9.922Zm-1.25 1.137a36.027 36.027 0 0 1-1.522-1.116C-5.077 4.97 1.842-1.472 6.454.293c.314.12.618.279.904.477L5.5 3 7 7l-1.5 3 1.815 4.537Zm-2.3-3.06-.442-1.106a1 1 0 0 1 .034-.818l1.305-2.61L4.564 3.35a1 1 0 0 1 .168-.991l1.032-1.24c-1.688-.449-3.7.398-4.456 2.128-.711 1.627-.413 4.55 3.706 8.229Z"></path>
                                                    </svg>
                                                )
                                            }
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                : <></>
            }
            {
                wishlist != null ?
                    <div className="text-center pt-md-4">
                        <div className="row">
                            {
                                wishlist.items
                                .sort((a,b) => a.priority - b.priority)
                                .map(item => {
                                    return <ViewWishlistItem key={item.id} wishlistId={wishlist.wishlistId} item={item} showReserved={showReserved} onReserve={() => handleReserve(item.id)} />
                                })
                            }
                        </div>
                    </div>
                    : <></>
            }
        </Container>

    );
}

export default ViewWishlist;