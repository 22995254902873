import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Modal, ListGroup, InputGroup } from 'react-bootstrap';
import { check_wishlist_by_url, update_wishlist_url } from './MyWisher'
import { publicWishlistUrl, urlWordBreak, copyToClipBoard } from './Util'

interface WishlistUrlSuggestParams {
    name: string; 
    wishlistId: string; 
    url: string;
    onEditToggle: React.MouseEventHandler<HTMLButtonElement>;
}

const WishlistUrl = (props: WishlistUrlSuggestParams) => {

    const shorthand = props.url;
    const usedId = shorthand ? shorthand : props.wishlistId;
    const wishlistPath = "/w/" + usedId;
    const fullWishlistUrl = publicWishlistUrl(usedId);

    return (
        <Row className="justify-content-md-center  mb-4 mt-4">
            <Col sm={12} md={8} lg={8}>
                <div className="">
                    <div style={{}}>                        
                        <p>
                        <i>Your public wishlist link is:</i>
                        </p>
                        <div style={{ float: "left", width: "90%"}}>
                            <div style={{ textAlign: "center"}}>
                                <p>
                                    <b>{urlWordBreak(fullWishlistUrl)}</b>
                                </p>
                            </div>
                        </div>
                        <div style={{ float: "right" }}>
                            <Button type="button" className="copy-url-button" onClick={() => copyToClipBoard(fullWishlistUrl)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"></path>
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"></path>
                                    </svg>
                            </Button>
                            {" "}
                            <Button onClick={props.onEditToggle} className="edit-url-button header-edit-url-toggle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                    <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z"></path>
                                    <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
                                </svg>
                            </Button>
                            {' '}
                            <a href={wishlistPath}>
                                <Button type="button" className="view-wish-list-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                    </svg>
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>);
}

interface WishlistUrlParams {
    show: boolean;
    handleClose: () => void;
    url: string;
    id: string;
    onUrlChange: (arg0: string) => void;
}

const WishlistUrlForm = (props: WishlistUrlParams) => {
    const show = props.show;
    const handleClose = props.handleClose;

    const [error, setError] = useState("");
    const [isUrlCheckLoading, setIsUrlCheckLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [niceLinkStr, setNiceLinkStr] = useState(props.url || "");
    const [isAvailable, setIsAvailable] = useState<boolean | null>(null);

    const generateCurrentLink = () => {
        const url = props.url;
        if (url) {
            return publicWishlistUrl(url);
        } else {
            return publicWishlistUrl(props.id);
        }
    }

    const urlChange = (e : any) => {
        var x = e.target.value;
        x = x.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '').slice(0, 49);
        if (x !== niceLinkStr) {
            setNiceLinkStr(x);
        }
    }

    // calls API when user has stopped typing (750ms break) after changing URL
    useEffect(() => {
        const timer = setTimeout(() => {
            
            if (typeof niceLinkStr !== 'string') {
                setIsAvailable(null);
                return;
            }

            if (niceLinkStr.length < 5) {
                setIsAvailable(null);
                return;
            }

            if (niceLinkStr.length === 40) {
                setIsAvailable(false);
                setError("Text cannot be 40 characters long");
                return;
            }

            if (niceLinkStr.length > 49) {
                setError("Nice link must be less than 50 characters");
                setIsAvailable(null);
                return;
            }

            if (niceLinkStr === props.url) {
                setError("");
                setIsAvailable(null);
                return;
            }

            const urlText = publicWishlistUrl(niceLinkStr);
            setError("");

            try {
                new URL(urlText); // validate
                console.debug(urlText);
                setIsUrlCheckLoading(true);

                check_wishlist_by_url(niceLinkStr).then(response => {
                    if (response.status === 200) {
                        setIsAvailable(false);
                    }
                    else if (response.status === 404) {
                        setIsAvailable(true);
                    } else {
                        throw response;
                    }
                }).catch(error => {
                    console.error(error);
                    setError("Failed, try again later..");
                }).finally(() => {
                    setIsUrlCheckLoading(false);
                });

                
            } catch (err) {
                setError("Link is invalid");
            }
        }, 750)

        return () => clearTimeout(timer)
    }, [niceLinkStr, props.url])


    const handleReset : React.MouseEventHandler<HTMLButtonElement> = (e) => {
        const url = props.url;
        if (url) {
            setNiceLinkStr(url);
        } else {
            setNiceLinkStr("");
        }
    }

    const handleSave : React.MouseEventHandler<HTMLButtonElement> = () => {
        setError("");
        setIsSaveLoading(true);
        update_wishlist_url(props.id, niceLinkStr).then(response => {
            if (response.status === 200) {
                props.onUrlChange(niceLinkStr);
                setNiceLinkStr(niceLinkStr);
                handleClose();
            } else if (response.status === 200) {
                props.onUrlChange(niceLinkStr);
                setIsAvailable(false);
            } else {
                throw response;
            }
        }).catch(error => {
            console.error(error);
            setError("Failed, try again later..");
        }).finally(() => {
            setIsSaveLoading(false);
        });
    }

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose a nice link name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <h2>Your current link is</h2>
                        <p><small>{urlWordBreak(generateCurrentLink())}</small></p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Form.Label>Your vanity URL</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                {publicWishlistUrl("")}
                            </InputGroup.Text>
                            <Form.Control id="vanity-url" value={niceLinkStr} onChange={urlChange} />
                        </InputGroup>
                        <div className="float-start">
                            <p style={{ color: "red", fontStyle: "italic" }}>{error}</p>
                        </div>
                        {isUrlCheckLoading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : <></>
                        }
                        <div className="float-end">
                            {
                                isAvailable === true ?
                                <p style={{ color: "green", fontStyle: "italic" }}>
                                The nice link is available!
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-heart-eyes" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                    <path d="M11.315 10.014a.5.5 0 0 1 .548.736A4.498 4.498 0 0 1 7.965 13a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242.63 0 1.46-.118 2.152-.242a26.58 26.58 0 0 0 1.109-.224l.067-.015.017-.004.005-.002zM4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434zm6.488 0c1.398-.864 3.544 1.838-.952 3.434-3.067-3.554.19-4.858.952-3.434z"></path>
                                </svg>
                            </p>
                            : <></>
                            }
                            {
                                isAvailable === false ?
                            <p style={{ color: "gold", fontStyle: "italic" }}>
                                Please try a different one!
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-expressionless" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                    <path d="M4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm5 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                </svg>
                            </p>
                            : <></>
}
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: "100%" }}>
                    <div className="float-start">
                        <p style={{ color: "red", fontStyle: "italic" }}>{error}</p>
                    </div>
                    <div className="float-end">
                        <Button variant="secondary" onClick={!isSaveLoading && !isUrlCheckLoading ? handleClose : undefined} disabled={isSaveLoading || isUrlCheckLoading} className="wishlist-popup-cancel">
                            Cancel
                        </Button>
                        {' '}
                        <Button variant="secondary" onClick={!isSaveLoading && !isUrlCheckLoading ? handleReset : undefined} disabled={isSaveLoading || isUrlCheckLoading} className="wishlist-popup-cancel">
                            Reset
                        </Button>
                        {' '}
                        <Button variant="primary" onClick={!isSaveLoading && !isUrlCheckLoading && isAvailable ? handleSave : undefined} disabled={isSaveLoading || isUrlCheckLoading || !isAvailable} className="wishlist-popup-save">
                            {isSaveLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : <></>
                            }
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>);
}

interface EditWishlistUrlParams {
    wishlist: {
        name: string;
        wishlistId: string;
        url: string;
    };
    onUrlChange: (newUrl: string) => void;
}

const EditWishlistUrl = (props: EditWishlistUrlParams) => {

    const [editMode, setEditMode] = useState(false);
    const name = props.wishlist.name;

    const handleEditToggle = () => {
        setEditMode(true);
    }
    const handleEditClose = () => {
        setEditMode(false);
    }

    return (
        <>
            <WishlistUrlForm id={props.wishlist.wishlistId} url={props.wishlist.url} show={editMode} handleClose={handleEditClose} onUrlChange={props.onUrlChange} />
            <WishlistUrl wishlistId={props.wishlist.wishlistId} url={props.wishlist.url} name={name} onEditToggle={handleEditToggle} />
            </>
    );

}

export default EditWishlistUrl;