import React from 'react';
import Navigation from './Navigation';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Advertising = () => {

    const emailStr = "contact (@) my-wisher.com";

    return (
        <Container>
            <Navigation />
            <Row className="justify-content-md-center">
                <Col sm={12} md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <img src="pic/store_128x128.png" alt="" align="left" style={{padding: "0 10px 0 10px"}} />
                                <h3 className="dark-text webfont"> Advertising on My Wishlist </h3>
                                <p style={{textAlign: "justify"}}>We offer a free and easy-to-use service to our users. Running this service is not free and we are therefore interested in running ads on our homepage. All requests regarding advertising can be sent by
                                    email to <span id='contact-email' className="contact-email" style={{whiteSpace: "nowrap"}}>{emailStr}</span>.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Advertising;