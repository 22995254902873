export interface WishlistItem {
    id: string,
    priority: number,
    name: string,
    text: string,
    link: string,
    image: string,
    price: string,
    visible: boolean,
    reserved: string | null, // datetime
    created: string,
    updated: string,
    archived: string | null,
}

export interface Invitation {
    accepted: string | null,
    expires: string | null
}

export interface Collaborator {
    userId: string,
    created: string,
    invitation: Invitation,
}

export interface Wishlist {
    wishlistId: string,
    name : string,
    ownerId: string,
    text: string,
    url: string,
    created: string,
    updated: string,
    items: WishlistItem[],
    collaborators: string[],
}

export interface WishlistHead {
    wishlistId: string,
    created: string,
    archived: string,
}

export function copyWishlist(wishlist : Wishlist) : Wishlist {
    return JSON.parse(JSON.stringify(wishlist));
}

export interface WishlistHeader {
    name : string,
    text: string,
    url: string,
}

export function copyWishlistToHeader(wishlist : Wishlist) : WishlistHeader {
    return {
        name: wishlist.name,
        text: wishlist.text,
        url: wishlist.url,
    };
}

export type NullableWishlist = Wishlist | null;

export interface ApiFavorite {
    wishlistId: string, 
    name : string, 
    created : string,
}

export interface Favorite {
    wishlistId: string, 
    wishlistName : string, 
    created : string,
    link: string,
}
