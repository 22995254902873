import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Spinner} from 'react-bootstrap';
import Navigation from './Navigation';
import { user_login, set_token } from './MyWisher'

function redirectToHome() {
    document.location.href = '/';
}

const Home = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isValidCredentials, setIsValidCredentials] = useState(false);
    const calledOnce = useRef(false);

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }

        calledOnce.current = true;
        if (params.has("email") && params.has("exp") && params.has("sig")) {
            user_login(params.get("email"), params.get("exp"), params.get("sig")).then(response => {
                if (response.status === 201) {
                    return response.json();
                } 
                setIsValidCredentials(false);
                throw new Error("Response was not ok");
            }).then(data => {
                set_token(data.token);
                setIsValidCredentials(true);           

                setTimeout(redirectToHome, 5000);
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            console.error("Query parameters are missing from URL");
        }
    });

    return (
        <Container>
            <Navigation />
            <div className="pt-md-4">
                <Row className="justify-content-md-center">
                    <Col sm={10} md={8} lg={8}>
                        <Row className="justify-content-end mb-4">
                            <Col sm={12} md={8} lg={8}>
                                <div className="m-2">
                                    {isLoading ? <Spinner size="lg" />
                                        :
                                        (
                                            isValidCredentials ?
                                                <div className="">
                                                    < img src="/pic/logo_64.png" alt="logo" align="left" className="me-4" />
                                                    <h1 className="">Thank you!</h1>
                                                    <p>You are now logged in</p>
                                                    <p style={{ fontStyle: "italic" }}>If you are not automatically redirected after 5 seconds, please go back to the <a href='/'>front page.</a> </p>
                                                </div>
                                                :
                                                <div className=""> <img src="/pic/logo_64.png" alt="logo" align="left" className="me-4" />
                                                    <h1 className="">Something went wrong!</h1>
                                                    <p>Make sure you have copied the link correctly!</p>
                                                </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        </Container >

    );
}

export default Home;