import React from 'react';
import { ApiFavorite, Favorite } from './Interfaces';

export function baseUrl() : string {
    const protocol = window.location.protocol;
    const host = window.location.host;
    return protocol + '//' + host;
}

export function publicWishlistUrl(idOrNiceUrl : string) : string{
    return baseUrl() + '/w/' + idOrNiceUrl;
}

// break url up and add wbr tags to avoid overflow on small screens
export function urlWordBreak(urlStr : string) {
    const index = urlStr.lastIndexOf("/");
    if (index===-1) {
        return urlStr;
    }
    const id = urlStr.substring(index + 1);
    const chunkList = id.match(/.{1,4}/g);
    if (chunkList === null) {
        return urlStr;
    }

    return [<span key="fst">{urlStr.substring(0, index+1)}</span>, chunkList.map((x) => (<span key={x}><wbr/>{x}</span>))];
}

export function copyToClipBoard(text: string) {
    navigator.clipboard.writeText(text);
}

export function mapFavorite(favorites : ApiFavorite[]) : Favorite[] {
    return favorites.map(x => { 
        const id = x.wishlistId.toLowerCase().trim();
        return { ...x, wishlistId: id, wishlistName: x.name, link: '/w/' + id } 
    });
}