import React from 'react';
import Navigation from './Navigation';
import { Container, Row, Col, Card } from 'react-bootstrap';

const UpdateChangeLog = (props) => {

    return (
        <Row className="justify-content-md-center mt-3">
            <Col sm={12} md={8} lg={6}>
                <Card>
                    <Card.Header><h4 className="dark-text" >{props.header}</h4></Card.Header>
                    <Card.Body>
                        <ul>
                            {props.changes.map((x) => { return (<li>{x}</li>); })}
                        </ul>
                    </Card.Body>
                </Card>
            </Col>
        </Row>);
}

const Updates = () => {

    const emailStr = "contact (@) my-wisher.com";

    return (
        <Container>
            <Navigation />
            <Row className="justify-content-md-center">
                <Col sm={12} md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <img src="pic/favorites_128x128.png" alt="" align="left" style={{ padding: "0 10px 0 10px" }} />
                            <h3 className="dark-text"> Updates, bugfixes and improvements </h3>
                            <p style={{}}>This page contains a list of updates or changes on my-wisher.com.
                            If you encounter problems or difficulties, feel free to send an email to <span id='contact-email' className="contact-email" style={{ whiteSpace: "nowrap" }}>{emailStr}</span>.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <UpdateChangeLog header="February 2023" changes={[
                "Update: major redesign and replatforming",
                "Feature: history of visited wishlist is added",
                "Feature: wishlists can be marked as favorites for easier access",
                "Bugfix: various improvements"
            ]} />
            <UpdateChangeLog header="December 2022" changes={[
                "Update: improved UI with response design",
                "Update: simplified UI",
                "Feature: list of favorite / recent wishlists is available",
                "Bugfix: javascript problems on mobil devices"
            ]} />
            <UpdateChangeLog header="November 2022" changes={[
               "Update: System update",
               "Bugfix: Problems solved with item priorities when creating a new wishlsit",
               "Bugfix: Problems solved using link on mobile devices",
               "Improvement: Better handling of image URLs",
            ]} />
            <UpdateChangeLog header="December 2020" changes={[
                "Feature: Ordering your items on the wishlsit is now easier. Every item has a numeric priority that can be changed easily",
            ]} />
            <UpdateChangeLog header="November 2020" changes={[
                "Change: The image search has been updated. Previoulsy this was powered by bing search but now it is powered by contextualwebsearch.",
                "Fix: Facebook login is working again.",
                "Fix: Some problems with google login has been resolved.",
            ]} />
            <UpdateChangeLog header="January 2017" changes={[
                "Feature: Google login is now possible to ease the login process.",
                "Feature: Facebook login is now possible to ease the login process.",
                "Change: Anonymous wishlist are no longer possible as it has become very easy to login..",
            ]} />
            <UpdateChangeLog header="December 2017" changes={[
                "Improvement: wishlists can be seen and accessed directly from the front page.",
                "Improvement: auto-suggestion on email fields is now active (as on most major sites).",
                "Bugfix: email problem fixed where users did not get an activation link. Email service is working again, the hosting service was blocking the emails.",
                "Bugfix: problem fixed where some browser could not remember the wishlists from one session to the next. Cookies were not handle correctly in some browsers due to a browser problem.",
            ]} />
            <UpdateChangeLog header="November 2017" changes={[
                "Bugfix: Image search is now working again, there was a problem with an account reneval on the Azure Cloud.",    
            ]} />
        </Container>
    );
}

export default Updates;