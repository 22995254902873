import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Spinner} from 'react-bootstrap';
import Navigation from './Navigation';
import { clean_token } from './MyWisher'


const Logout = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const calledOnce = useRef(false);

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }

        calledOnce.current = true;

        clean_token();
        
        setIsSuccessful(true);
        setIsLoading(false);
    });

    return (
        <Container>
            <Navigation />
            <div className="pt-md-4">
                <Row className="justify-content-md-center">
                    <Col sm={10} md={8} lg={8}>
                        <Row className="justify-content-end mb-4">
                            <Col sm={12} md={8} lg={8}>
                                <div className="m-2">
                                    {isLoading ? <Spinner size="lg" />
                                        :
                                        (
                                            isSuccessful ?
                                                <div className="">
                                                    < img src="/pic/logo_64.png" alt="logo" align="left" className="me-4" />
                                                    <h1 className="">Goodbye...</h1>
                                                    <p style={{ fontStyle: "italic" }}>We hope to see you soon! </p>
                                                </div>
                                                :
                                                <div className=""> <img src="/pic/logo_64.png" alt="logo" align="left" className="me-4" />
                                                    <h1 className="">Something went wrong!</h1>
                                                    <p>Try deleting all cookie manually if the problem persists!</p>
                                                </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        </Container >

    );
}

export default Logout;