import React, { useState } from 'react';
import { Spinner, Button, Modal } from 'react-bootstrap';
import { WishlistItem } from './Interfaces'

const WishlistDeletePopup = (props: { wishlistId: string, item: WishlistItem; show: boolean, onCancel: (id: string) => void; onDeleted: (id: string) => void; }) => {
    const item = props.item;
    const id = item.id;
    const show = props.show;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleDelete = () => {
        setLoading(true);
        setError("");
        try {
            props.onDeleted(id);
        } catch (error) {
            console.error("error", error);
            setError("Failed");
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Modal show={show} onHide={() => props.onCancel(id)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Are you sure you want to delete '{item.name}'?</h2>
                </Modal.Body>
                <Modal.Footer>
                <div style={{ width: "100%" }}>
                        <div className="float-start">
                            <p style={{ color: "red", fontStyle: "italic" }}>{error}</p>
                        </div>
                        <div className="float-end">
                            <Button variant="secondary" onClick={!loading ? (_) => props.onCancel(id) : undefined} disabled={loading} className="close-wish-delete-popup">
                                Close
                            </Button>
                            {' '}
                            <Button variant="primary" onClick={!loading ? (_) => handleDelete() : undefined} disabled={loading} className="delete-wish-delete-popup">
                                {loading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : <></>
                                }
                                Delete
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WishlistDeletePopup;