import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { get_wishlist } from './MyWisher'
import { NullableWishlist } from './Interfaces'

const WishlistHeaderComponent = (props: {
    id: string;
    onDelete: (id: any) => void;
    deleteError: string;
    isDeleting: any;
}) => {

    const [wishlist, setWishlist] = useState<NullableWishlist>(null);
    const calledOnce = useRef(false);

    const fallbackImgUrl = 'pic/gift-3.png';

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }
        calledOnce.current = true;
        get_wishlist(props.id)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.error("response error", response);
                }
                throw response;
            }).then(data => {
                setWishlist(data);
            }).catch(error => {
                console.error(error);
            }).finally(() => {

            });
    }, [props.id]);

    const [toggle, setToggle] = useState(false);

    const deleteToggle = () => {
        setToggle(true);
    }

    const disableDeleteToggle = () => {
        setToggle(false);
    }

    const handleDelete = () => {
        props.onDelete(props.id);
    }

    const isUndefined = wishlist === null;
    const items = isUndefined ? [] : wishlist.items;
    const isEmpty = items.length === 0;

    return (
        <Row className="wishlist-header justify-content-center gy-5 mb-4">
            <div className='col-12 col-sm-12 col-md-8 col-lg-8'>
                <Card className='card'>
                    <Card.Header className='text-start' style={{ background: '#f8f8f8' }}>
                        <Card.Title className="card-title">{wishlist?.name}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <>
                            {
                                isUndefined ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    :
                                    (isEmpty ?
                                        <>
                                            <img style={{ width: "23%", opacity: "80%" }} src={fallbackImgUrl} alt="example" />
                                            <img style={{ width: "23%", opacity: "80%" }} src={fallbackImgUrl} alt="example" />
                                            <img style={{ width: "23%", opacity: "80%" }} src={fallbackImgUrl} alt="example" />
                                        </>
                                        :
                                        items.filter(item => !item.archived)
                                        .slice(0, 4)
                                        .map(item => {
                                            return <img style={{ width: "23%" }} key={item.id} src={item.image} alt={item.name} onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = fallbackImgUrl;
                                            }} />
                                        })
                                    )
                            }
                        </>
                    </Card.Body>
                    <Card.Footer className='text-end' style={{ background: '#f8f8f8' }}>
                        <Row>
                            <Col>
                                <Button type="button" onClick={deleteToggle} className="trash-wishlist" style={{ float: "right" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>{' '}
                                    TRASH
                                </Button>
                                {' '}
                                <a href={"/e/" + props.id} style={{ float: "left", marginRight: "5px" }}>
                                    <Button type="button" className="edit-wishlist">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                            <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z"></path>
                                            <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
                                        </svg>{' '}
                                        EDIT
                                    </Button>
                                </a>
                                {' '}
                                <a href={"/w/" + props.id} style={{ float: "left" }}>
                                    <Button type="button" className="view-wishlist">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                        </svg>{' '}
                                        VIEW
                                    </Button>{' '}
                                </a>
                            </Col>
                        </Row>
                        {toggle ?
                            <Row>
                                <Col style={{ textAlign: "left" }}>
                                    <span style={{ color: "red" }}>{props.deleteError}</span>
                                </Col>
                                <Col>
                                    <Button type="button" variant="danger" onClick={handleDelete} className="trash-wishlist-yes" style={{ float: "right", marginTop: "5px" }}>
                                        {props.isDeleting ? <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> : <></>
                                        }
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg>{' '}
                                        YES
                                    </Button>
                                    <Button type="button" variant="secondary" onClick={disableDeleteToggle} className="trash-wishlist-no" style={{ float: "right", marginRight: "5px", marginTop: "5px" }}>
                                        NO
                                    </Button>
                                </Col>
                            </Row> : <></>
                        }
                    </Card.Footer>
                </Card>
            </div>
        </Row>);
}

export default WishlistHeaderComponent;