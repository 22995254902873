import React, { useState } from 'react';
import { Card, Modal, Button, Col, Spinner } from 'react-bootstrap';
import { WishlistItem } from './Interfaces';
import { reserve_item } from './MyWisher'

import './ViewWishlistItem.css';

interface ViewWishlistItemsParams {
    showReserved: boolean;
    wishlistId: string;
    item: WishlistItem;
    onReserve: () => void;
}

const ViewWishlistItem = (props: ViewWishlistItemsParams) => {

    const [showWtbDialog, setShowWtbDialog] = useState(false);
    const [isWtbLoading, setIsWtbLoading] = useState(false);
    const [wtbError, setWtbError] = useState("");

    const showReserved = props.showReserved;

    const reservedDate : Date | null = props.item.reserved !== null ? new Date(props.item.reserved) : null;
    
    const threeMonths = 3 * 30 * 24 * 60 * 60 * 1000;
    const expirationDate = reservedDate !== null ? new Date(reservedDate.getTime() + threeMonths) : new Date();
    const now = new Date();
    const isReserved = reservedDate !== null && now <= expirationDate;

    const handleReservation = () => {
        setWtbError("");
        setIsWtbLoading(true);

        reserve_item(props.wishlistId, props.item.id, null).then(response => {
            if (response.status === 200 || response.status === 201) {
                return response.json();
            }
            console.error("response error", response);
            setWtbError("Failed to reserve");
        }).then(_ => {
            setShowWtbDialog(false);
            props.onReserve();
        }).catch(error => {
            console.error(error);
            setWtbError("Unexpected error");
        }).finally(() => {
            setIsWtbLoading(false);
        });
    }

    return (
        <>
            <Col sm={6} md={4} lg={3} className='card-container wishlist-item-card-container'>
                <Card className='rounded-3 mb-4'>
                    <div className='m-2'>
                        <Card.Body style={{
                            backgroundImage: `url("${props.item.image}"), url("/pic/gift-2.png")`,
                            backgroundSize: "cover",
                            backgroundPosition: "50%",
                            backgroundRepeat: "no-repeat",
                            aspectRatio: "1 / 1"
                        }}>
                        </Card.Body>
                        <Card.Footer style={{ background: "#f8f8f8" }}>
                            <h6 className='card-title'>{props.item.name}</h6>
                        </Card.Footer>

                        <Card.ImgOverlay className='wishlist-item-overlay'>
                            <div style={{ paddingBottom: "var(--bs-card-img-overlay-padding)" }}>
                                <a href={props.item.link} target='_blank' rel="noopener noreferrer" className='btn btn-primary' style={{ marginRight: "5px", marginBottom: "5px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                                        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                    </svg>
                                    LINK
                                </a>
                                {" "}
                                <Button id='$reserveButtonPopupId' className='btn btn-primary' onClick={() => setShowWtbDialog(true)} disabled={!showReserved} style={{ marginBottom: "5px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart4" viewBox="0 0 16 16">
                                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                                    </svg>
                                    RESERVE
                                </Button>
                            </div>
                            <h5 className='card-title'>{props.item.name}</h5>
                            <p className='card-text'><small>{props.item.price}</small></p>
                            <p className='card-text'>{props.item.text}</p>
                        </Card.ImgOverlay>
                        {isReserved && showReserved ? <Card.ImgOverlay id='$reservedOverlayId' className='wishlist-item-overlay-reserved'>
                            <div style={{ paddingBottom: "var(--bs-card-img-overlay-padding)" }} >
                                <h3 className='reserved-rotate'> RESERVED </h3>
                            </div>
                        </Card.ImgOverlay> : <></>}
                    </div>
                </Card>
            </Col>
            {
                <Modal show={showReserved && showWtbDialog} onHide={() => setShowWtbDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className=' fs-5'>{props.item.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to reserve this item?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ width: "100%" }}>
                            <div style={{ float: "left" }}>
                                <p style={{ color: "red" }}>{wtbError}</p>
                            </div>
                            <div style={{ float: "right" }}>
                                <Button variant="secondary" onClick={() => setShowWtbDialog(false)} disabled={isWtbLoading} style={{ marginRight: "5px", marginBottom: "5px" }}>Close</Button>
                                <Button variant="primary" onClick={handleReservation} disabled={isWtbLoading} style={{ marginRight: "5px", marginBottom: "5px" }}>
                                    {isWtbLoading ? <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : <></>}
                                    Save changes
                                </Button>
                                {isReserved ?
                                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-bag-heart' viewBox='0 0 16 16'>
                                        <path fillRule='evenodd' d='M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5Zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0ZM14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z' />
                                    </svg> : <></>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default ViewWishlistItem;